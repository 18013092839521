import { LinkWithIcon } from '@components/LinkWithIcon';

import { CmsVideoSection } from '@interfaces/common';

import styles from './VideoSection.module.css';

export type VideoSectionProps = Omit<CmsVideoSection, '_key' | '_type'>;

export const VideoSection: React.VFC<VideoSectionProps> = ({
	textColor,
	backgroundColor,
	heading,
	paragraph,
	link,
	youtubeVideoId,
	youtubeVideoTitle,
}) => {
	const customProperties = {
		'--video-section-text-color': textColor,
		'--video-section-background-color': backgroundColor,
		'--iframe-aspect-ratio': '16/9',
	} as React.CSSProperties;

	return (
		<section className={styles['video-section']} style={customProperties}>
			<div className={styles['content-wrapper']}>
				{heading ? <h2 className="fluid-font-t2">{heading}</h2> : null}
				{paragraph ? <p className="fluid-font-t5">{paragraph}</p> : null}
				{link ? <LinkWithIcon className={styles.cta} link={link} /> : null}
				<div className={styles['iframe-wrapper']}>
					<iframe
						allow="accelerometer; gyroscope;"
						allowFullScreen
						frameBorder="1600"
						height="900"
						loading="lazy"
						src={`https://www.youtube.com/embed/${youtubeVideoId}?rel=0`}
						title={youtubeVideoTitle}
					/>
				</div>
			</div>
		</section>
	);
};
