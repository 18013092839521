import { GetStaticProps } from 'next';

import { BuySection, BuySectionProps } from '@components/BuySection';
import {
	CarouselSection,
	CarouselSectionProps,
} from '@components/CarouselSection';
import { HeroHeadline, HeroHeadlineProps } from '@components/HeroHeadline';
import { ImageSection, ImageSectionProps } from '@components/ImageSection';
import {
	IngredientSection,
	IngredientSectionProps,
} from '@components/IngredientSection';
import { LogoMarquee, LogoMarqueeProps } from '@components/LogoMarquee';
import { SplitSection, SplitSectionProps } from '@components/SplitSection';
import { VideoSection, VideoSectionProps } from '@components/VideoSection';

import { getHomePage } from '@data/sanity/queries';
import { CmsHomePageDocument } from '@interfaces/common';
import { neverError } from '@utils/neverError';
import { withSiteWideData } from '@utils/page';

interface HomePageProps {
	homePage: CmsHomePageDocument;
}

const HomePage: React.FC<HomePageProps> = ({ homePage: { sections } }) => {
	let isFirstLevelHeadingSet = false;

	return (
		<article>
			{sections?.map(({ _type, _key, ...rest }) => {
				switch (_type) {
					case 'heroHeadline': {
						const headingLevel = !isFirstLevelHeadingSet ? 1 : 2;

						isFirstLevelHeadingSet = true;

						return (
							<HeroHeadline
								headingLevel={headingLevel}
								key={_key}
								{...(rest as HeroHeadlineProps)}
							/>
						);
					}
					case 'imageSection': {
						return <ImageSection key={_key} {...(rest as ImageSectionProps)} />;
					}
					case 'splitSection':
						return <SplitSection key={_key} {...(rest as SplitSectionProps)} />;
					case 'ingredientSection': {
						return (
							<IngredientSection
								headingFontSize={2}
								headingLevel={2}
								key={_key}
								{...(rest as IngredientSectionProps)}
							/>
						);
					}
					case 'imageTickerSection': {
						return (
							<LogoMarquee
								bgColor="var(--color-future-green)"
								key={_key}
								showTitle
								size="large"
								{...(rest as LogoMarqueeProps)}
							/>
						);
					}
					case 'buySection': {
						const { buyOffline, buyOnline } = {
							...(rest as BuySectionProps),
						};
						const sectionIsHidden = buyOffline.isHidden && buyOnline.isHidden;

						return !sectionIsHidden ? (
							<BuySection key={_key} {...(rest as BuySectionProps)} />
						) : null;
					}
					case 'videoSection': {
						return <VideoSection key={_key} {...(rest as VideoSectionProps)} />;
					}
					case 'carouselSection': {
						return (
							<CarouselSection key={_key} {...(rest as CarouselSectionProps)} />
						);
					}
					case undefined:
						// Newly added section types come back as undefined
						// We don’t want to display an error in this case
						return null;
					default: {
						throw neverError('Unknown section type', _type);
					}
				}
			})}
		</article>
	);
};

export const getStaticProps: GetStaticProps = withSiteWideData<HomePageProps>(
	async ({ locale }) => {
		const homePage = await getHomePage(locale);

		if (!homePage) {
			return {
				notFound: true,
			};
		}

		return {
			props: {
				metadata: homePage.metadata || null,
				homePage,
				newsletterTheme: 'light',
			},
		};
	}
);

export default HomePage;
