import { LinkWithIcon } from '@components/LinkWithIcon';
import { LogoLinks } from '@components/LogoLinks';

import { CmsBuySection, CmsLinkRecord } from '@interfaces/common';

import styles from './BuySection.module.css';

export interface BuySectionProps extends CmsBuySection {
	link: CmsLinkRecord;
}

export const BuySection: React.VFC<BuySectionProps> = ({
	buyOnline,
	buyOffline,
	link,
}) => {
	return (
		<section className={styles.wrapper}>
			<>
				{!buyOnline.isHidden ? (
					<>
						<p className="fluid-font-t4">{buyOnline.text}</p>
						<LogoLinks layout="buttons" links={buyOnline.links} />
					</>
				) : null}
				{!buyOffline.isHidden ? (
					<LogoLinks layout="grid" links={buyOffline.links} />
				) : null}
				{link ? <LinkWithIcon link={link} /> : null}
			</>
		</section>
	);
};
