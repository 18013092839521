import classNames from 'classnames';

import { SanityImage } from '@components/SanityImage';

import { CmsLinkWithImage } from '@interfaces/common';
import { breakpoints } from '@utils/device';

import styles from './LogoLinks.module.css';

interface LogoLinksProps {
	links: CmsLinkWithImage[];
	layout: 'buttons' | 'grid';
}

export const LogoLinks: React.VFC<LogoLinksProps> = ({ links, layout }) => {
	return (
		<ul className={classNames(styles.list, styles[layout])}>
			{links.map(({ id, url, image, color }) => {
				const logoStyle = {
					'--stockist-logo-aspect-ratio': image.width / image.height,
					'--stockist-logo-background-color': color?.hex,
				} as React.CSSProperties;

				return (
					<li className={styles.item} key={id}>
						<a
							className={styles.link}
							href={url}
							rel="noreferrer noopener"
							style={logoStyle}
							target="_blank"
						>
							<SanityImage
								className={styles.logo}
								image={image}
								sizes={`${breakpoints.lg} 3.75rem, 2.25rem`}
							/>
						</a>
					</li>
				);
			})}
		</ul>
	);
};
