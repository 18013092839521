import Link from 'next/link';
import classNames from 'classnames';

import { Svg } from '@components/Svg';

import { CmsLinkRecord } from '@interfaces/common';

import styles from './LinkWithIcon.module.css';

interface LinkWithIconProps {
	link: CmsLinkRecord;
	className?: string;
}

export const LinkWithIcon: React.VFC<LinkWithIconProps> = ({
	link,
	className,
}) => {
	const { url, text } = link;
	return (
		<div className={classNames(styles['link-wrapper'], className)}>
			<Link href={url} passHref>
				<a className={classNames(styles.link, 'fluid-font-t6-bold')} href="">
					{text} <Svg aria-hidden id="arrow-left" />
				</a>
			</Link>
		</div>
	);
};
